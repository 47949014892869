/**
 *
 *
 *
 */
export const CREATE_LEAD = `
  mutation EnrollCreateLead($first_name: String!, $last_name: String!, $email: String!, $submitted_at: DateTime!, $platform: String!) {
    enroll_create_lead(first_name: $first_name, last_name: $last_name, email: $email, submitted_at: $submitted_at, platform: $platform)
  }
`;
