/**
 *
 *
 *
 */
import React from 'react';
import { darken } from 'polished';
import styled from 'styled-components';
import Spinner from '../../_components/Spinner';
import T from '../../_components/Typography';
import * as u from '../../utils';
import * as F from '../frgs';


/**
 *
 *
 *
 */
export default function Register() {
  const [ isLoading, setIsLoading ] = React.useState(false);
  const [ error, setError ] = React.useState(false);
  const [ success, setSuccess ] = React.useState(false);
  const [ firstName, setFirstName ] = React.useState('');
  const [ lastName, setLastName ] = React.useState('');
  const [ email, setEmail ] = React.useState('');

  return (
    <S.Container>
      <S.TopImage src="/assets/mlx.png" />
      <S.FormContainer>
        {process.env.NODE_ENV === 'development' && (
          <React.Fragment>
            <S.Button $color={'#FD8C73'} onClick={onCreateFake}>
              <T.IL6 color="white">Fake Data</T.IL6>
            </S.Button>
            <div style={{ height: 15 }} />
          </React.Fragment>
        )}
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>First Name</T.IL5>
          </S.SectionTopContainer>
          <S.Input {...onFirst()} />
        </S.SectionContainer>
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>Last Name</T.IL5>
          </S.SectionTopContainer>
          <S.Input {...onLast()} />
        </S.SectionContainer>
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>Email</T.IL5>
          </S.SectionTopContainer>
          <S.Input {...onEmail()} />
        </S.SectionContainer>
        <S.SectionContainer>
          {getMessage()}
        </S.SectionContainer>
        <S.Button onClick={onSubmit} disabled={isLoading || success}>
          {isLoading ? <Spinner /> : <T.IL6 color="white">{success ? 'Check your email' : 'Enroll'}</T.IL6>}
        </S.Button>
      </S.FormContainer>
    </S.Container>
  );


  /**
   *
   *
   *
   */
  function onFirst() { return { value: firstName, onChange: e => setFirstName(e.target.value) }; }
  function onLast() { return { value: lastName, onChange: e => setLastName(e.target.value) }; }
  function onEmail() { return { value: email, onChange: e => setEmail(e.target.value) }; }

  /**
   *
   *
   *
   */
  function getMessage() {

    if (error) return (
      <T.IL5 color="red">Something went wrong</T.IL5>
    );

    if (success) return (
      <T.IL5 color="green">We have sent you an email with a link to complete your application</T.IL5>
    );

    return (
      <T.IL5>We will send you an email with a link to complete your application</T.IL5>
    );
  }


  /**
   *
   *
   *
   */
  async function onSubmit(evt) {
    evt.preventDefault();
    setError(false);
    setIsLoading(true);
    const payload = getPayload();
    const [data, _] = await u.fetcher(F.CREATE_LEAD, payload);
    setIsLoading(false);
    if (data?.id) setSuccess(true);
    else setError(true);

    /**
     *
     *
     *
     */
    function getPayload() {
      return {
        first_name: firstName,
        last_name: lastName,
        email,
        submitted_at: new Date().toISOString(),
        platform: 'website',
      };
    }
  }


  /**
   *
   *
   *
   */
  function onCreateFake(evt) {
    evt.preventDefault();
    setFirstName('John');
    setLastName('Doe');
    setEmail('izaaksofer@gmail.com');
  }
};


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 400px;
  margin: 0px auto;
  padding-bottom: 50px;
  margin-top: 20px;
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

S.TopImage = styled.img`
  width: 400px;
  height: 161px;
  object-fit: cover;
  margin-bottom: 50px;
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
`;

S.FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid rgb(216, 222, 228);
  background: rgb(246, 248, 250);
`;

S.SectionContainer = styled.div`
  margin-bottom: 20px;
`;

S.SectionTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

S.Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid rgb(208, 215, 222);
  border-radius: 6px;
  padding-left: 6px;
  font-family: 'Inter';
  font-size: 14px;
`;

S.Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  background: ${p => p.$color || p.theme.accent};
  border: 1px solid ${p => p.$color || 'rgba(31, 35, 40, 0.15)'};
  &:hover { cursor: pointer; background: ${p => darken(0.1, (p.$color || p.theme.accent))}; }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
