/**
 *
 *
 *
 */
import React from 'react';
import * as Router from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { GlobeStyle, Theme } from '../_components/Style';
import Analytics from '../_components/Analytics';
import Header from '../_components/Header';
import Index from './pages';


/**
 *
 *
 *
 */
export default function Accounts() {
  return (
    <ThemeProvider theme={Theme.light}>
      <Router.BrowserRouter basename="/">
        <GlobeStyle />
        <Header />
        <Analytics />
        <S.PageContainer>
          <Index />
        </S.PageContainer>
      </Router.BrowserRouter>
    </ThemeProvider>
  );
};


/**
 *
 *
 *
 */
const S = {};

S.PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1200px;
  padding-top: 60px;
  padding-bottom: 10px;
  margin: auto;
  height: 100vh;
  @media (max-width: 1220px) {
    padding-top: 60px;
  }
`;